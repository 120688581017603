import React, { useState } from "react";
import Feasto from "../assets/feasto";
import { Grid, IconButton, Avatar, useMediaQuery } from "@mui/material";
import PersonOutlineRoundedIcon from "@mui/icons-material/PersonOutlineRounded";
import { Logo } from "../Styles";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import SideNavBarWebView from "./SideNavBarWebView";
import { CloseOutlined } from "@mui/icons-material";

export default function TopNavBarWebView({ index }) {
  const navigate = useNavigate();
  function getReturnType(index) {
    switch (index) {
      case 0:
      case 4:
      case 6:
      case 100:
      case 99:
        return "double";
      case 1:
      case 2:
      case 3:
      case 5:
      case 7:
        return "single";
      default:
        return "single";
    }
  }
  const singleOrDouble = getReturnType(index);
  const isMediumScreen = useMediaQuery(
    singleOrDouble === "single"
      ? "(min-width:700px) and (max-width:1070px)"
      : "(min-width:700px) and (max-width:1350px)"
  );

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const handleProfileClick = () => {
    navigate("/vendor/vendorProfile");
  };

  const user = JSON.parse(localStorage.getItem("user"));
  const vendorName = user ? user.vendorName : null;

  const handleDrawerToggle = () => {};
  return (
    <Grid
      sx={{
        border: "1px solid var(--gray-200, #E5E7EB)",
        position: "sticky",
        top: 0,
        zIndex: 1205,
      }}
    >
      {" "}
      <Grid
        style={{
          backgroundColor: "#FFF",
          padding: "0px 24px",
          display: "flex",
          alignItems: "center",
          alignSelf: "stretch",
          justifyContent: "space-between",
          height: "64px",
        }}
      >
        <div
          style={{
            backgroundColor: "#FFF",
            margin: "0px",
            padding: "0px 0px",
            display: "flex",
            alignItems: "center",
            alignSelf: "stretch",
            justifyContent: "space-between",
            height: "64px",
            gap: "24px",
          }}
        >
          {isMediumScreen &&
            (isDrawerOpen ? (
              <CloseOutlined
                onClick={handleDrawerClose}
                sx={{ fontSize: "40px" }}
              />
            ) : (
              <MenuIcon onClick={handleDrawerOpen} sx={{ fontSize: "40px" }} />
            ))}

          <Logo style={{ paddingLeft: "0px", paddingBottom: "40px" }}>
            <Feasto />
          </Logo>
        </div>{" "}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingRight: "0px",
            justifyContent: "space-evenly",
            gap: "16px",
          }}
        >
          <IconButton
            onClick={handleProfileClick}
            style={{
              paddingTop: "8px",
            }}
          >
            {user && user !== undefined && user.vendorName ? (
              <Avatar style={{ color: "#FFF" }}>
                {vendorName[0].toUpperCase()}
              </Avatar>
            ) : (
              <Avatar>
                <PersonOutlineRoundedIcon sx={{ color: "#FFF" }} />
              </Avatar>
            )}
          </IconButton>
        </div>
      </Grid>
      <SideNavBarWebView
        isDrawerOpen={isDrawerOpen}
        onClose={handleDrawerClose}
        index={index}
      />
    </Grid>
  );
}
